/* eslint-disable no-inline-comments */
import React from 'react'
import { Book } from 'packages/shared/interfaces/Book'
import { graphql } from 'gatsby'
import { Typography } from '@taglivros/tag-components'
import CategoryPreviousKitsHeader from '@site/components/organisms/CategoryPreviousKitsHeader'
import CategoryCollection from '@site/components/organisms/CategoryCollection'
import * as S from '../_categoryStyles'
import BaseStructure from '@site/components/organisms/BaseStructure'
import CommonHead from '@shared/meta/CommonHead'
import SubscriptionOffer from '@site/components/organisms/SubscriptionOffer'

export function Head() {
  return (
    <CommonHead
      title="Kits Passados | Curadoria | TAG Experiências Literárias"
      description="Confira os livros que foram indicados por grandes nomes do cenário cultural. Receba uma edição exclusiva e com capa dura direto na sua casa."
    />
  )
}

const KitsPassadosCuradoria = ( { data }: KitsPassadosCuradoria.Props ): React.ReactNode => {
  const { allStrapiKitsPassados, allStrapiHomes } = data
  const previousKitsData = allStrapiKitsPassados.edges[ 0 ].node
  const booksCuradoria = previousKitsData.curadoriaBooks
  const { clubs } = allStrapiHomes.edges[ 0 ].node

  return (
    <S.Container>
      <BaseStructure clubs={ clubs }>
        <CategoryPreviousKitsHeader category="curadoria" />
        <Typography
          as="p"
          color="GREY-700-TXT"
          fontSizeMobile="xs"
          fontSizeDesktop="s"
          fontWeight="s"
          fontFamily="notoSans"
          variant="customText"
        >{previousKitsData.curadoriaDescription}
        </Typography>
        <CategoryCollection
          books={ booksCuradoria }
          category="curadoria"
        />
      </BaseStructure>
    </S.Container>
  )
}

namespace KitsPassadosCuradoria {
  export interface Props {
    data: {
      allStrapiKitsPassados: {
        edges: [
          {
            node: {
              title: string
              curadoriaDescription: string
              curadoriaBooks: [
                {
                  book: Book
                }
              ]
            }
          }
        ]
      }
      allStrapiHomes: {
        edges: [
          {
            node: {
              clubs: SubscriptionOffer.Props
            }
          }
        ]
      }
    }
  }
}

export default KitsPassadosCuradoria

export const query = graphql`{
  allStrapiKitsPassados(limit: 1, sort: {order: DESC, fields: strapiId}) {
    edges {
      node {
        title
        curadoriaDescription
        curadoriaBooks {
          book {
            id
            book {
              id
              title
              authorship
              additionalInformation
              tagMonth
              appRating
              urlStore
            }
            bookImage {
              starHexColor
              flagHexColor
              id
              cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              background {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            bookGallery {
              caption
              id
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            aboutBook {
              aboutAuthorship
              authorshipPronoun
              customTextParagraph
              customTitleParagraph
              id
              synopsis
            }
            comments {
              rating
              comment
              name
              id
            }
          }
        }
      }
    }
  }
  allStrapiHomes(limit: 1, sort: {order: DESC, fields: strapiId}) {
    edges {
      node {
        clubs {
          subtitle
          title
          image {
            mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            desktop {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          description
          price
          cta {
            text
            link
          }
          footer
        }
      }
    }
  }
}
`
